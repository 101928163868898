import { Component, Vue } from 'vue-property-decorator';
import AvailabilityRequest from '@/components/AvailabilityRequest/AvailabilityRequest.vue';

@Component<AgreedHour>({
  components: {
    AvailabilityRequest,
  },
})
export default class AgreedHour extends Vue {
  public $pageTitle = 'Afgesproken uren';

  protected requestVisibility: any = {
    showWeekChanger: true,
  }
}
